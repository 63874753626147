<script>
import router from "@/router";
import { layoutComputed } from "@/state/helpers";

import Topbar from "@/components/topbar";
import SideBar from "@/components/side-bar";
import {
  SET_SANDBOX_MODE_ALERT,
  SET_UPDATE_ALERT
} from "@/state/actions.type";

export default {
  components: { Topbar, SideBar },
  data() {
    return {
      isMenuCondensed: false,
      updateAlertAvailable: process.env.VUE_APP_UPDATE_ALERT_AVAILABLE,
      updateAlertMsg: process.env.VUE_APP_UPDATE_ALERT_MESSAGE_MSG,
      updateAlertTime: process.env.VUE_APP_UPDATE_ALERT_MESSAGE_TIME,
      updateAlertTitle: process.env.VUE_APP_UPDATE_ALERT_MESSAGE_TITLE,
    };
  },
  computed: {
    ...layoutComputed,
    sandBoxModeAlertShow() {
      return this.$store.state.auth.sandboxModeAlert && !this.$store.state.auth.currentUser.is_live_mode;
    },
    updateAlertShow(){
      return this.$store.state.auth.updateAlert;
    },
    formattedDate() {
      if(!this.updateAlertTime || this.updateAlertTime.includes("VUE_APP_UPDATE_ALERT_MESSAGE_TIME")){
        return;
      }
      const isoString = this.updateAlertTime;
      const date = new Date(isoString);

      function getDayOfWeek(date) {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysOfWeek[date.getDay()];
      }

      function getOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
        switch (day % 10) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      }

      const day = date.getDate();
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      const dayOfWeek = getDayOfWeek(date);
      const ordinalSuffix = getOrdinalSuffix(day);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const timeZoneAbbreviation = date.toLocaleTimeString('en-US', { timeZoneName: 'long' });
      const parts = timeZoneAbbreviation.split(" ");
      const timeZoneAbbreviation1 = parts.slice(2).join(" ");

      return `${day}${ordinalSuffix} ${month}, ${year} (${dayOfWeek}) at ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'} (${timeZoneAbbreviation1})`;
    },
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          // document.body.classList.add("sidebar-enable");
          // document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          // document.body.classList.add("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    hideSandBoxModeAlert(value) {
      this.$store.dispatch(`auth/${SET_SANDBOX_MODE_ALERT}`, value);
    },
    hideUpdateAlert(){
      this.$store.dispatch(`auth/${SET_UPDATE_ALERT}`, false);
    },
    // sidebarStyle() {
    //   if(this.sandBoxModeAlertShow && this.updateAlertShow) {
    //     if(localStorage.getItem('user.language') === '"vn"') {
    //       return 'top: 200px';
    //     }
    //     return 'top: 180px';
    //   }
    //   else if((!this.sandBoxModeAlertShow && this.updateAlertShow) || (this.sandBoxModeAlertShow && !this.updateAlertShow)){
    //     if(localStorage.getItem('user.language') === '"vn"') {
    //       if(this.sandBoxModeAlertShow && !this.updateAlertShow){
    //         return 'top: 140px';
    //       }
    //       else{
    //         return 'top: 130px';
    //       }
    //     }
    //     if(!this.sandBoxModeAlertShow && this.updateAlertShow){
    //       return 'top: 130px';
    //     }
    //     else{
    //       return 'top: 120px';
    //     }
    //   }
    //   return '';
    // },
    // mainContentStyle() {
    //   if(this.sandBoxModeAlertShow && this.updateAlertShow) {
    //     if(localStorage.getItem('user.language') === '"vn"') {
    //       return 'position: relative; top: 130px';
    //     }
    //     return 'position: relative; top: 110px';
    //   }
    //   else if((!this.sandBoxModeAlertShow && this.updateAlertShow) || (this.sandBoxModeAlertShow && !this.updateAlertShow)) {
    //     if(localStorage.getItem('user.language') === '"vn"') {
    //       if(this.sandBoxModeAlertShow && !this.updateAlertShow){
    //         return 'position: relative; top: 65px';
    //       }
    //       else{
    //         return 'position: relative; top: 60px';
    //       }
    //     }
    //     if(!this.sandBoxModeAlertShow && this.updateAlertShow){
    //       return 'position: relative; top: 54px';
    //     }
    //     else{
    //       return 'position: relative; top: 44px';
    //     }
    //   }
    //   return '';
    // }
  },
};
</script>

<template>
  <div>
    <!-- Begin page -->
    <b-alert variant="danger" :show="sandBoxModeAlertShow" @input="hideSandBoxModeAlert" class="text-center position-sticky top-0 m-0" dismissible><b-icon icon="exclamation-circle-fill" variant="dark"></b-icon> {{ $t('sandbox_mode_alert.current') }} <b>{{ $t('sandbox_mode_alert.sandbox_mode') }}</b> {{ $t('sandbox_mode_alert.testing_purpose') }} <b>{{ $t('sandbox_mode_alert.live_mode') }}</b> {{ $t('sandbox_mode_alert.end_text') }}</b-alert>
    <b-alert variant="warning" :show="updateAlertShow" @input="hideUpdateAlert" class="text-left position-sticky top-0 m-0" style="background-color: #FF7628; color: #fff;" dismissible>
      <div class="d-flex align-items-center">
        <b-badge class="mx-2" pill variant="light" style="padding: 15px 25px; font-size:16px; font-weight: 350; border-radius: 8px;">UPDATE</b-badge>
          <div class="alertMsg" style="font-size:16px; font-weight: 350; line-height: 20px;">
                {{ this.updateAlertTitle }} on {{ this.formattedDate }} {{ this.updateAlertMsg }}
          </div>
      </div>
    </b-alert>
    <div id="layout-wrapper" class="d-flex">
      <SideBar
        :is-condensed="isMenuCondensed"
        :type="leftSidebarType"
        :width="layoutWidth"
      />
      <!-- :style="sidebarStyle()" -->
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <!-- :style="mainContentStyle()" -->
      <div class="main-content flex-fill">
        <Topbar />
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>